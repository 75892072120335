
import { Component, Vue, } from 'vue-property-decorator'
@Component({})
export default class NotFound extends Vue {
  private role = this.$store.state.AuthModule.AuthState.role
  private ADMIN_ROLES = process.env.VUE_APP_PRIVILEGED_ROLES?.split(",") || [];
  private go () {
    if (!this.ADMIN_ROLES.some((adminRole:any) => this.role.includes(adminRole))) {
      this.$router.push('/startup/activity')
    } else {
      this.$router.push('/app')
    }
  }
}
